// import { ColorModeScript } from "@chakra-ui/color-mode";
import React from "react";

import { PageWrapper } from "./src/components/PageWrapper";

export const wrapPageElement = ({ element, props }) => {
  return <PageWrapper location={props.location}>{element}</PageWrapper>;
};

export const wrapRootElement = ({ element }) => {
  return (
    <>
      {/* <ColorModeScript initialColorMode={"dark"} /> */}
      {element}
    </>
  );
};
