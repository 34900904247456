import React, { ReactNode } from "react";

import {
  ChakraProvider, extendTheme
} from "@chakra-ui/react";

interface PageWrapperProps {
  location: Location;
  children: ReactNode;
}

const defaultTheme = {
  useSystemColorMode: true,
  initialColorMode: "system",
  shadows: {
    outline: "0 0 0 2px rgba(66, 153, 225, 0.6)",
  },
  colors: {
    background: {
      light: "#f9fffd",
      dark: "#1A202C",
    },
    brand: {
      green: "#e3fae9",
      blue: "#c4f1f9",
    },
  },
  components: {
    Button: {
      baseStyle: {
        borderRadius: 4,
      },
    },
    // Input: {
    //   baseStyle: {
    //     field: {
    //       borderColor: "gray.600",
    //       color: "gray.600",
    //       _hover: {
    //         borderColor: "gray.600",
    //       },
    //     },
    //     // borderRadius: 1,
    //     // backgrounColor: "red",
    //   },
    // },
  },
  styles: {
    global: (props) => ({
      "html, body": {
        color: props.colorMode === "dark" ? "gray.300" : "gray.700",
        backgroundColor: props.colorMode === "dark" ? "gray.800" : "#f9fffd",
      },
    }),
  },
};

export const PageWrapper = ({ location, children }: PageWrapperProps) => {
  let customTheme: any = defaultTheme;

  // console.log("PageWrapper debug path name", location.pathname);

  if (location.pathname.match(/^\/app/)) {
    customTheme = {
      ...customTheme,
      styles: {
        global: (props) => ({
          "html, body": {
            color: props.colorMode === "dark" ? "gray.300" : "gray.700",
            backgroundColor:
              props.colorMode === "dark" ? "gray.800" : "#f3fbf5", // "rgb(237, 243, 236)", //"#f1f8f6", //"#f9fffd", //"#f9f9f9", //"gray.50",
          },
        }),
      },
    };
  }
  // if (location.pathname == "/n/") {
  //   customTheme = {
  //     ...customTheme,
  //   };
  // }

  // if (location.pathname != "/n/" || !location.pathname.match(/^\/app/)) {
  //   customTheme = {
  //     ...customTheme,
  //     useSystemColorMode: false,
  //     initialColorMode: "light",
  //   };
  // }
  // location.pathname.match(/^\/app/) ||
  // location.pathname.match(/^\/docs/) ||
  // location.pathname === "/" ||
  // location.pathname.match(/^\/npage/) ||
  // location.pathname.match(/^\/login/) ? (
  return (
    <>
      {/* <ColorModeScript initialColorMode={config.initialColorMode} /> */}
      <ChakraProvider
        theme={extendTheme(customTheme)}
        portalZIndex={40}
        resetCSS={true}
      >
        {/* <ColorModeProvider options={config}> */}
        {/* <CSSReset /> */}
        {children}
        {/* </ColorModeProvider> */}
      </ChakraProvider>
    </>
  );
};
